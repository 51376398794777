'use strict';

var dom = require('form-widget-dom');
var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);
var emitter = require('emitter');

var getLinkedToIds = function($target) {

	var linkObj = $target.data('linked-to-ids');

	return linkObj ? linkObj.linkIds : [];

};

var getLinkedFromIds = function($target) {

	var linkObj = $target.data('linked-from-ids');

	return linkObj ? linkObj.linkIds : [];

};

var getElementByLinkId = function(id) {

	return dom.rows.find('[data-link-id="' + id + '"]');

};

var getSelectedOption = function($col) {

	var $els;

	if (isRadioType($col) || isCheckboxType($col)) {

		$els = $col.find(':checked');

	} else {

		$els = $col.find(':selected');

	}

	return $els;

};

var getOptionCoords = function($option) {

	var id = $option[0].id;
	var parts = id.split('-');

	return {
		selector: '#' + id,
		type: parts[0],
		row: +parts[1] + 1,
		col: +parts[2] + 1,
		option: +parts[3] + 1
	};

};

var getExtraFees = function() {

	var extraFees = [];

	dom.fbForm.find('.fb-option-field-with-fees :checked.fb-option-field-has-fee').each(function() {

		var $selectedOption = $(this);
		var optionCoords = getOptionCoords($selectedOption);

		extraFees.push({
			row: optionCoords.row,
			col: optionCoords.col,
			option: optionCoords.option
		});

	});

	return extraFees;

};

var isLinkableField = function($element) {

	return $element.hasClass('fb-option-template');

};

var isRadioBtn = function($element) {

	return $element.is('[type="radio"]');

};

var isRadioType = function($col) {

	return $col.hasClass('fb-radio-type');

};

var isCheckboxType = function($col) {

	return $col.hasClass('fb-checkbox-type');

};

var isSelectOption = function($element) {

	return $element.is('option');

};

var isOptionSelected = function($option) {

	var isSelected;

	if (isSelectOption($option)) {

		// Select box.
		isSelected = $option.prop('selected');

	} else {

		// Radio or checkbox.
		isSelected = $option.prop('checked');

	}

	return isSelected;

};

var showLinkedField = function($target) {

	$target.removeClass('fb-linked-hide');

	emitter.emit('fbWidget-linked-field-shown', $target);

	dom.triggerPublicFormEvent({
		type: 'fbWidget-linked-field-shown'
	});

};

var hideLinkedField = function($target) {

	$target.addClass('fb-linked-hide');

	emitter.emit('fbWidget-linked-field-hidden', $target);

	dom.triggerPublicFormEvent({
		type: 'fbWidget-linked-field-hidden'
	});

};

var hideLinkedFields = function() {

	// Get all the options that are linked to other fields.
	var $optionsWithLinks = dom.fbForm.find('[data-linked-to-ids]');

	$optionsWithLinks.each(function(i) {

		var $this = $optionsWithLinks.eq(i);
		var linkIds = getLinkedToIds($this);

		// Hide all the fields linked to this option.
		$.each(linkIds, function(i, linkId) {

			hideLinkedField(getElementByLinkId(linkId));

		});

	});

};

var resetSelect = function($select) {

	$select.prop('selectedIndex', 0);

	emitter.emit('fbWidget-dropdown-reset', $select);

};

var unSelect = function($option) {

	if (isSelectOption($option)) {

		resetSelect($option.closest('select'));

	} else {

		$option.prop('checked', false);

	}

};

var processLinkIds = function($option) {

	var linkedToIds = getLinkedToIds($option);

	// Loop over all the link Ids for this option.
	$.each(linkedToIds, function(i, linkedToId) {

		// Find the field with this link Id.
		var $linkedToField = getElementByLinkId(linkedToId);
		var linkedFromIds;
		var isOtherOptionSelected;
		var $selectedOption;

		if (isOptionSelected($option)) {

			// Show the field linked to this option.

			showLinkedField($linkedToField);

			if (isLinkableField($linkedToField)) {

				// The field being shown could have linked options.
				// Look for selected options in this field and check
				// if they have links.

				$selectedOption = getSelectedOption($linkedToField);

				processLinkedFields($selectedOption);

			}

		} else {

			// This option is not selected. Hide fields linked to this option,
			// but only if another selected option on the form isn't also
			// linked to it.

			// Get all options that link to the field being hidden.
			linkedFromIds = getLinkedFromIds($linkedToField);
			isOtherOptionSelected = false;

			// Check if other options linked to the field being hidden are
			// selected. If they are, we don't want to hide the field.
			$.each(linkedFromIds, function(i, linkedFromId) {

				var $option = getElementByLinkId(linkedFromId);

				if (isOptionSelected($option)) {

					isOtherOptionSelected = true;

					// Break out of jQuery each.
					return false;

				}

			});

			if (!isOtherOptionSelected) {

				hideLinkedField($linkedToField);

				if (isLinkableField($linkedToField)) {

					// The field being hidden could have linked options.
					// Look for selected options in this field and check
					// if they have links. Unselect the option to attempt
					// to hide all linked fields.

					$selectedOption = getSelectedOption($linkedToField);

					unSelect($selectedOption);

					processLinkedFields($selectedOption);

				}

			}

		}

	});

};

var processLinkedFields = function($option) {

	var $optionList;
	var $otherOptions;
	var isOptionRadio = isRadioBtn($option);
	var isOptionSelectOption = isSelectOption($option);

	processLinkIds($option);

	if (isOptionRadio || isOptionSelectOption) {

		// Get the other options in this group and process them.

		if (isOptionRadio) {

			$optionList = $option.closest('.fb-field-option-list');
			$otherOptions = $optionList.find('input').not($option);

		} else {

			$optionList = $option.closest('select');
			$otherOptions = $optionList.find('option').not($option);

		}

		$otherOptions.each(function(i) {

			processLinkIds($otherOptions.eq(i));

		});

	}

};

var checkForFees = function($el) {

	var $fieldContainer = dom.getFieldContainer($el);

	if ($fieldContainer.hasClass('fb-option-field-with-fees')) {

		emitter.emit('fbWidget-option-field-with-fees-changed');

	}

};

var addEventHandlers = function() {

	dom.fbForm.off('.fb-option');

	dom.fbForm.on('change.fb-option', '.fb-option-template input', function() {

		var $this = $(this);

		checkForFees($this);
		processLinkedFields($this);

	});

	dom.fbForm.on('change.fb-option', '.fb-dropdown-type select', function() {

		var $this = $(this);

		checkForFees($this);
		processLinkedFields($this.find(':selected'));

	});

};

module.exports = {

	init: function() {

		addEventHandlers();
		hideLinkedFields();

	},

	isOptionType: function($col) {

		// Checking template type, since "option" can mean radio, checkbox
		// or dropdown type.
		return $col.hasClass('fb-option-template');

	},

	getSelectedOption: getSelectedOption,

	// Used to disable an option if it was available on form load, but not
	// on form submission.
	disable: function($option) {

		var $target;
		var $select;

		unSelect($option);

		$option.attr('disabled', '');

		if (isSelectOption($option)) {

			$target = $option;

			// Reset the dropdown and trigger a change event so linked fields
			// will get processed.
			$select = $option.closest('select');
			resetSelect($select);
			$select.change();

		} else {

			$target = $option.next();

			processLinkedFields($option);

		}

		$target.html($target.attr('data-unavailable-msg'));

	},

	getOptionCoords: getOptionCoords,

	getVal: function($col) {

		var $els = getSelectedOption($col);
		var val = '';

		$els.each(function(i) {

			val += i ? ', ' : '';

			val += $els.eq(i).val();

		});

		return {
			name: dom.getFieldTitleText($col),
			value: val
		};

	},

	getExtraFees: getExtraFees

};
